import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'styles';
import { breakpoint } from 'utils/mediaQueries';

const StyledGrid = styled(Grid)`
  z-index: 1;
`;
const DotActive = styled.div`
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  opacity: 1;
  outline: none;
  background-color: ${colors.shades.purple.normal};
  ${breakpoint.down('sm')`
  width:1.8rem;height:1.8rem`}
`;
const Dot = styled.div`
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  opacity: 0.6;
  background-color: ${colors.shades.purple.light};
  cursor: pointer;
  outline: none;
  ${breakpoint.down('sm')`
  width:1.4rem;height:1.4rem`}
`;
class DotsStepper extends React.Component {
  render() {
    const { maxSteps, activeStep, handleStepChange } = this.props;
    return (
      <StyledGrid
        container
        item
        justify="space-between"
        alignItems="center"
        xs={12}
        sm={5}
        md={3}
        lg={2}
      >
        {maxSteps.map((step, index) => {
          if (index !== activeStep) {
            return <Dot key={index} onClick={() => handleStepChange(index)} />;
          } else {
            return <DotActive key={index} />;
          }
        })}
      </StyledGrid>
    );
  }
}
export default DotsStepper;
